import * as React from "react";
import Layout from "../../layout";
import Industry from "../../components/Industries/Industry";
import { Community } from "../../components/Industries/Community/Community";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";

// markup
const Industries = () => {
  const data = useStaticQuery(graphql`
    query industries {
      prismicIndustries {
        dataRaw
      }
    }
  `);

  const industries = get(
    data,
    "prismicIndustries.dataRaw.industries",
    []
  ).filter(
    (item) =>
      item.image &&
      item.image.url &&
      item.description &&
      item.description.length > 0
  );

  return (
    <Layout
      title={"Industries"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      {industries.map((item, idx) => {
        return (
          <Industry
            title={get(item, "title", "")}
            key={idx}
            description={get(item, "description", [])}
            image={get(item, "image.url", "")}
            desktopRightImage={idx % 2 === 1}
          />
        );
      })}
      <br />
      <Community />
    </Layout>
  );
};

export default Industries;
