import React from "react";
import Puzzle from "../../../images/SandboxIcons/puzzle.svg";
import { Link } from "gatsby";

export const Community = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12 pt-md-0 text-center">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-7 mx-auto text-center">
            <img
              src={Puzzle}
              className="ms-auto me-auto icon-svg icon-svg-md mb-4"
              alt=""
            />
            <h2 className="display-4 mb-3">Ready to get started?</h2>
            <p className="lead fs-lg mb-6 px-xl-10 px-xxl-15">
              We are trusted by over 220+ clients. Get in touch with the most
              accurate positioning partner in Sri Lanka
            </p>
            <Link
              to={"/user/new-connection?redirectTo=register"}
              className="btn btn-primary rounded"
            >
              Request a Connection
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
