import React from "react";
import { PrismicRichText } from "@prismicio/react";

const Industry = ({ image, title, description, desktopRightImage = false }) => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div
            className={`col-lg-6 position-relative ${
              desktopRightImage ? "order-lg-2" : ""
            }`}
          >
            <figure className="rounded mb-0">
              <img src={image} alt={title} />
            </figure>
          </div>
          <div className="col-lg-6">
            <h3 className="display-4 mb-4">{title}</h3>
            <PrismicRichText field={description} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industry;
